.arrow-more {
  display: inline-block;
  height: rem(24px);
  overflow: hidden;

  &__text-block {
    display: flex;
    align-items: center;
    transition: all 0.25s ease-in-out;

    .arrow-more__arrow-more {
      fill: $light-blue;
    }

    .arrow-more__arrow-more {
      color: $light-blue;
    }
  }

  &__link-text {
    @include interface-bold;

    display: inline-block;
    font-size: rem(16px);
    line-height: rem(20px);
    color: $light-blue;
    padding-right: rem(16px);
    text-transform: uppercase;
    transition: color 0.25s ease-in;
  }

  &:hover {
    .arrow-more__text-block {
      transform: translateY(rem(-24px));
    }
  }
  .tech-slider__item-content:hover & .arrow-more__text-block {
    transform: translateY(rem(-24px));
  }
}
