.preloader {
	display: none;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1005;
	background-color: #09101B;

	&__inner {
		margin-bottom: 31px;
		position: relative;
		width: 72px;
		height: 72px;
		overflow: hidden;
	}

	&__path {
		position: absolute;
		top: 0;
		stroke-dasharray: 1000;
		stroke-dashoffset: 1000;
		animation: dash 15s linear forwards;
		transform: rotateY(180deg);
		transition: opacity 0.5s ease-out;

		&.hide {
			opacity: 0;
		}
	}

	&__second-path {
		position: absolute;
		top: 0;
		transition: opacity 0.5s ease-out;
		opacity: 0;

		&.active {
			opacity: 1;
		}
	}

	&__text {
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.01em;
		font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
	}
}

@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}
