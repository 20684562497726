$blue: #1f72fa;
$light-blue: #16cffe;
$dark-blue: #105ad2;
$orange: #f64a14;
$black: #09101b;
$dark-gray: #09101b;
$gray: #4d5460;
$light-gray: #cfd5de;
$white: #ffffff;
$red: #ca2525;
$black-blue: #172133;
$ocean-blue: #2f3ec2;
$scarlet: #7e2420;
$beige: #b4965a;

$desktop: 1399px;
$tablet: 1023px;
$tabletM: 988px;
$tabletS: 768px;
$mobile: 567px;
