.arrow {
  &--right {
    width: rem(48);
    height: rem(24);
    fill: $blue;
    transition: fill 0.3s;
  }

  &--right-blue {
    width: rem(48);
    height: rem(24);
    fill: $light-blue;
    transition: fill 0.3s;
  }

  &--one {
    animation-fill-mode: forwards;
    fill: $blue;
    transition: all 0.7s ease;
  }

  &--text {
    fill: $light-blue;
  }
}
