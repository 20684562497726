.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  padding-bottom: 20px;

  @media (max-width: $tablet) {
    padding-bottom: 0;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(64px) 0;
    width: rem(1200px);
    margin-left: rem(200px);

    @media (max-width: $desktop) {
      width: rem(1056px);
      margin-left: rem(72px);
      padding: 0.3374rem 0;
    }

    @media (max-width: $tablet) {
      width: rem(670px);
      margin-left: rem(48px);
      padding: rem(43px) 0;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }

    @media (max-width: $tabletM) {
      margin-left: rem(16px);
      padding: rem(24px) 0;
    }
  }

  &__left {
    width: 50%;

    @media (max-width: $tablet) {
      width: 90%;
    }
  }

  &__right {
    width: rem(480px);

    display: flex;
    align-items: center;
    gap: 10px;

    a {
      margin-left: auto;

      @media (max-width: $mobile) {
        margin-left: unset;
      }
    }

    @media (max-width: $desktop) {
      width: rem(528px);
    }

    @media (max-width: $tablet) {
      width: 90%;
    }

    @media (max-width: $mobile) {
      flex-wrap: wrap;
      align-items: flex-end;
    }
  }

  &__right span {
    font-size: rem(18px);
    line-height: rem(24px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
    white-space: nowrap;

    @media (max-width: $mobile) {
      font-size: rem(18px);
      display: block;
      letter-spacing: rem(0.2px);
    }
  }
}
