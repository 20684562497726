html {
  font-size: 16 / 1600 * 100vw;

  @media (min-aspect-ratio: 19/10) {
    font-size: 16 / 1800 * 100vw;
  }

  @media (max-width: $desktop) {
    font-size: 16 / 1200 * 100vw;
  }

  @media (max-width: $desktop) and (min-aspect-ratio: 16/10) {
    font-size: 16 / 1400 * 100vw;
  }

  @media (max-width: $tabletM) {
    font-size: 16 / 768 * 100vw;
  }

  @media (max-width: $mobile) {
    font-size: 16 / 320 * 100vw;
  }

  &.open-modal {
    max-height: 100%;
    overflow: hidden;

    body {
      overflow: hidden;
    }

    .content {
      max-height: 100vh;
    }
  }
}

body {
  position: relative;
}

.section {
  height: 100vh;
  position: relative;

  @media (max-width: $tablet) {
    height: auto;
    display: flex;
    align-items: center;
  }

  &__subtitle {
    text-align: center;
    font-size: rem(24px);
    line-height: rem(32px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    @media (max-width: $mobile) {
      font-size: rem(18px);
      line-height: rem(24px);
    }
  }
}

.container {
  margin: 0 auto;
  max-width: rem(1540px);
  padding: rem(41px) rem(50px);

  @media (max-width: $desktop) {
    max-width: rem(1056px);
    padding: rem(33px) 0;
  }

  @media (max-width: $tablet) {
    width: rem(672px);
    margin: 0 auto;
    padding: rem(25px) 0;
  }

  @media (max-width: $mobile) {
    width: 100%;
    padding: rem(16px);
  }
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.subtitle {
  position: absolute;

  @include interface-bold();
  font-size: rem(16px);
  line-height: rem(20px);
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
  color: $white;

  transform: rotate(-90deg) !important;
  animation: animation-name 1.3s ease-in;

  @media (max-width: $desktop) {
    display: none;
  }

  &_hero {
    top: rem(123px);
    left: rem(-138px);
  }

  &_technology {
    top: rem(245px);
    left: rem(37px);
  }

  &_projects {
    top: rem(222px);
    left: rem(61px);
  }

  &_contacts {
    top: rem(35px);
    left: rem(-144px);
  }

  &_works {
    top: rem(222px);
    left: rem(61px);

    @media (max-width: $desktop) {
      display: block;
    }

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &_blog {
    top: rem(222px);
    left: rem(61px);

    @media (max-width: $desktop) {
      display: block;
    }

    @media (max-width: $tablet) {
      display: none;
    }
  }
}

.content {
  min-height: 100vh;

  @media (max-width: $tablet) {
    overflow: hidden;
  }
}

.download {
  position: relative;
  padding-right: rem(32px);

  color: $blue;
  font-size: rem(18px);
  line-height: rem(24px);
  letter-spacing: 0.01em;
  font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
  transition: 0.5s;

  &:hover {
    color: $light-blue;

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.00004 11.7394L3.17269 8.26014L1.82738 9.74006L6.51355 14L1.00049 14L1.00049 16L15.0005 16L15.0005 14L9.48698 14L14.1727 9.74002L12.8273 8.26018L9.00004 11.7397L9.00004 -1.9789e-06L7.00004 -2.00275e-06L7.00004 11.7394Z' fill='%2316CFFE'/%3E%3C/svg%3E%0A");
    }
  }

  &:after {
    display: inline-block;
    width: rem(16px);
    height: rem(16px);
    margin-left: rem(5px);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.00004 11.7394L3.17269 8.26014L1.82738 9.74006L6.51355 14L1.00049 14L1.00049 16L15.0005 16L15.0005 14L9.48698 14L14.1727 9.74002L12.8273 8.26018L9.00004 11.7397L9.00004 -1.9789e-06L7.00004 -2.00275e-06L7.00004 11.7394Z' fill='%231F72FA'/%3E%3C/svg%3E%0A");
    background-size: rem(16px);
    content: '';
    transition: background 0.25s;
  }
}

.arrow-down {
  position: absolute;
  bottom: 0;
  left: rem(-88px);

  height: rem(47px);
  width: rem(23px);

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='48' viewBox='0 0 24 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 43.5859L11 -5.55442e-07L13 -4.68019e-07L13 43.5857L22.2928 34.2929L23.707 35.7071L12.707 46.7071L11.9999 47.4142L11.2928 46.7071L0.292815 35.7071L1.70703 34.2929L11 43.5859Z' fill='white'/%3E%3C/svg%3E%0A");

  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='48' viewBox='0 0 24 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 43.5859L11 -5.55442e-07L13 -4.68019e-07L13 43.5857L22.2928 34.2929L23.707 35.7071L12.707 46.7071L11.9999 47.4142L11.2928 46.7071L0.292815 35.7071L1.70703 34.2929L11 43.5859Z' fill='%2316CFFE'/%3E%3C/svg%3E%0A");
  }

  @media (max-width: $desktop) {
    left: 0;
  }

  @media (max-width: $tablet) {
    left: rem(56px);
  }

  @media (max-width: $mobile) {
    display: none;
  }

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='48' viewBox='0 0 24 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 43.5859L11 -5.55442e-07L13 -4.68019e-07L13 43.5857L22.2928 34.2929L23.707 35.7071L12.707 46.7071L11.9999 47.4142L11.2928 46.7071L0.292815 35.7071L1.70703 34.2929L11 43.5859Z' fill='%2316CFFE'/%3E%3C/svg%3E%0A");
  }

  &--absolute {
    bottom: rem(-24px);
    left: rem(-112px);
    height: rem(100px);

    @media (max-width: $desktop) {
      left: 0;
    }

    @media (max-width: $tablet) {
      left: rem(56px);
    }
  }
}

.fullpage-wrapper {
  overflow: hidden;
}

.grecaptcha-badge {
  visibility: hidden;
}

@keyframes animation-name {
  0% {
    opacity: 0;
    transform: rotate(-90deg) translateY(-120px);
  }
  100% {
    opacity: 1;
    transform: rotate(-90deg) translateY(0);
  }
}
