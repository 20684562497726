.blog-content {
	position: relative;
	width: 100%;
	padding-top: rem(192px);

	@media (max-width: $mobile) {
		padding-top: rem(80px);
	}

	&__wrapper {
		padding-bottom: rem(350px);
		margin-left: rem(200px);
		margin-right: rem(80px);

		@media (max-width: $desktop) {
			margin-left: rem(160px);
			margin-right: rem(72px);
			padding-bottom: rem(300px);
		}

		@media (max-width: $tablet) {
			margin: 0 rem(48px);
			padding-bottom: rem(100px);
		}

		@media (max-width: $mobile) {
			margin: 0;
			padding-bottom: rem(100px);
		}
	}
}