.blog-list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&__item {
		position: relative;
		width: calc(30%);
		margin-bottom: rem(112px);

		@media (max-width: $desktop) {
			width: rem(440px);
			margin-bottom: rem(90px);
		}

		@media (max-width: $tablet) {
			width: rem(448px);
			margin-bottom: rem(65px);
		}

		@media (max-width: $mobile) {
			position: relative;
		}
	}

	&__img-block {
		background: #fff;
		width: 100%;
		height: auto;
	}

	&__img {
		display: block;
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	&__block-link {
		display: block;

		&:hover {
			.blog-list__header {
				color: $blue;
			}

			.blog-list__text-block {
				transform: translateY(rem(-24px));
			}
		}
	}

	&__content {
		padding: rem(32px) 0 0;

		@media (max-width: $mobile) {
			padding: rem(16px) rem(16px) 0;
		}
	}

	&__date {
		font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
		font-size: rem(14px);
		line-height: rem(24px);
		cursor: default;
		margin-bottom: 8px;
		padding-left: 2px;
		color: $gray;
	}

	&__header {
		@include interface-bold;

		margin: 0 0 rem(28px);
		font-size: rem(34px);
		line-height: rem(40px);
		cursor: pointer;
		transition: color 0.25s ease-in;

		@media (max-width: $mobile) {
			font-size: rem(34px);
			line-height: rem(40px);
		}
	}

	&__text {
		margin: 0 0 rem(33px);
		font-size: rem(18px);
		line-height: rem(24px);
		cursor: pointer;
		color: $gray;


		@media (max-width: $mobile) {
			color: #fff;
		}
	}

	&__link {
		display: inline-block;
		height: rem(24px);
		overflow: hidden;
	}

	&__text-block {
		display: flex;
		align-items: center;
		transition: all 0.25s ease-in-out;

		.project-list__arrow-more {
			fill: $light-blue;
		}

		.project-list__arrow-more {
			color: $light-blue;
		}
	}

	&__link-text {
		@include interface-bold;

		display: inline-block;
		font-size: rem(16px);
		line-height: rem(20px);
		color: $light-blue;
		padding-right: rem(16px);
		text-transform: uppercase;
		transition: color 0.25s ease-in;
	}
}