.article-detail {
  p,
  ul,
  ol {
    margin-bottom: 1rem;
  }

  h3 {
    font-size: rem(30px);

    @media (max-width: $mobile) {
      font-size: rem(18px);
    }
  }

  h4 {
    font-size: rem(24px);

    @media (max-width: $mobile) {
      font-size: rem(16px);
    }
  }
}
