.article {
	h2 {
		margin-top: 0;
		margin-bottom: rem(32px);
		font-weight: 600;
		font-size: rem(34px);
		line-height: rem(40px);
		letter-spacing: rem(0.8px);

		@media (max-width: $mobile) {
			margin-bottom: rem(16px);
			font-size: rem(20px);
			line-height: rem(24px);
		}
	}
	* + h2 {
		margin-top: 56px;
	}
	h3 {}
	p {
		margin: 0;
		font-size: rem(24px);
		line-height: rem(32px);

		@media (max-width: $mobile) {
			font-size: rem(18px);
			line-height: rem(24px);
		}
	}
	ul,
	ol {
		list-style: none;
		counter-reset: item;
		font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
		padding-left: 24px;

		@media screen and (max-width: $mobile) {
			padding-left: 0;
		}
	}
	ol li {
		&:before {
			counter-increment: item;
			content: counter(item) '. ';
			color: #1F72FA;
			position: absolute;
			left: 0;
		}
	}
	ul li {
		&:before {
			display: inline-block;
			vertical-align: middle;
			width: rem(8px);
			height: rem(8px);
			border-radius: 50%;
			margin-right: rem(14px);
			background-color: #16CFFE;
			content: '';
			left: 0;
			top: 10px;
			position: absolute;
		}
	}
	li {
		margin: 0;
		font-size: rem(24px);
		line-height: rem(32px);
		padding-left: 24px;
		position: relative;

		@media (max-width: $mobile) {
			font-size: rem(18px);
			line-height: rem(24px);
		}
	}
	li + li {
		margin-top: 16px;
	}

	a {
		color: #1F72FA;

		&:hover {
			color: #16CFFE;
		}
	}

	img {
		vertical-align: middle;
		max-width: 100%;
		height: auto;
		margin: 0 auto;
		display: block;
	}

	.info__inner {
		margin-top: 0;
	}
}