.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__func {
    display: flex;
    align-items: center;
  }

  &__burger {
    display: none;

    @media (max-width: $tabletM) {
      display: block;
    }
  }

  &__logo {
    margin-right: 20px;
  }

  &__nav {
    margin-right: rem(200px);

    @media (max-width: $desktop) {
      margin-right: rem(306px);
    }

    @media (max-width: $tablet) {
      margin-right: rem(66px);
    }

    @media (max-width: $tabletM) {
      display: none;
    }
  }

  &__lang {
    @media (max-width: $tabletM) {
      display: none;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__img {
    width: rem(192px);
    height: rem(40px);

    @media (max-width: $tabletM) {
      width: rem(154px);
      height: rem(32px);
    }
  }

  &__text {
    margin-top: rem(2px);
    margin-left: rem(18px);

    color: $white;
    font-weight: 600;
    font-size: rem(22px);
    line-height: rem(32px);

    @media (max-width: $tabletM) {
      margin-top: rem(-2px);
      margin-left: rem(12px);

      font-size: rem(16px);
      line-height: rem(20px);
      letter-spacing: rem(0.5px);
    }
  }
}

.nav {
  display: flex;
  align-items: center;

  &__item {
    margin-right: rem(32px);

    @media (max-width: $tablet) {
      margin-right: rem(24px);
    }
  }

  &__item:last-of-type {
    margin-right: 0;
  }

  &__link {
    @include interface-bold();
    font-size: rem(18px);
    line-height: rem(24px);
    transition: all 0.3s;

    &:hover {
      color: $light-blue;
    }

    &.active {
      position: relative;

      &:before {
        position: absolute;
        bottom: rem(-12px);
        width: 100%;
        border-bottom: rem(2px) solid #fff;
        content: '';
        transition: all 0.3s;
      }

      &:hover:before {
        border-bottom-color: $light-blue;
      }
    }
  }
}

.lang {
  @include interface-bold();
  font-size: rem(18px);
  line-height: rem(24px);
  transition: color 0.3s;

  &:hover {
    color: $light-blue;
  }
}

.scroll-menu {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  padding: 0 rem(80px);
  justify-content: space-between;
  align-items: center;
  transition: top 0.5s ease-in-out;

  @media (max-width: $desktop) {
    padding: 0 rem(72px);
  }

  @media (max-width: $tablet) {
    padding: rem(16px) rem(48px);
    background-color: #1f72fa;
  }

  @media (max-width: $tabletM) {
    padding: rem(8px) rem(16px);
  }

  &.active,
  &.open {
    top: rem(41px);
    z-index: 1001;

    @media (max-width: $desktop) {
      top: rem(32px);
    }

    @media (max-width: $tablet) {
      top: 0;
    }
  }

  &__pic {
    display: block;
    width: rem(40px);
    height: rem(46px);

    @media (max-width: $tabletM) {
      width: rem(28px);
      height: rem(32px);
    }
  }

  &__burger {
    width: rem(24px);
    height: rem(24px);
    display: block;

    svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: #fff;
      transition: fill 0.25s ease-out;

      &.icon-cross {
        display: none;
      }
    }

    &:hover {
      svg {
        fill: #16cffe;

        @media (max-width: $tablet) {
          fill: #fff;
        }
      }
    }
  }

  &.open &__burger {
    svg {
      &.icon-cross {
        display: block;
      }

      &.icon-menu {
        display: none;
      }
    }
  }
}

.menu {
  position: fixed;
  top: -150%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: black;
  z-index: 1000;
  transition: top 0.25s ease-out, height 0.25s ease-out;

  &.open {
    top: 0;
    height: 100%;
  }

  &__wrapper {
    display: flex;
    height: 100vh;
    overflow-y: auto;

    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }

  &__block {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: rem(40px) rem(80px) rem(60px);
    border-right: 1px solid rgba(#ffffff, 0.1);

    @media (max-width: $tablet) {
      width: 100%;
      border: none;
      padding: rem(40px) rem(48px) rem(55px);
    }

    @media (max-width: $tabletM) {
      display: none;
      padding: rem(40px) rem(16px) rem(32px);
    }
  }

  &__block:first-of-type {
    @media (max-width: $tablet) {
      display: block;
      height: auto;
      padding-top: rem(130px);
    }

    @media (max-width: $tabletM) {
      display: block;
      padding-top: rem(62px);
    }
  }

  & .contacts__phone {
    margin-bottom: rem(42px);

    @media (max-width: $tabletM) {
      margin-bottom: rem(24px);
    }
  }

  &__nav {
    width: rem(480px);
    margin-top: rem(40px);

    @media (max-width: $tablet) {
      display: block;
      width: rem(450px);
      margin-top: 0;
      margin-bottom: rem(80px);
    }

    @media (max-width: $tabletM) {
      width: 100%;
      margin-bottom: rem(19px);
    }
  }

  &__nav-link {
    display: block;
    padding-bottom: rem(16px);
    padding-top: rem(16px);
    border-bottom: 1px solid rgba(#ffffff, 0.1);

    @include interface-bold();
    font-size: rem(18px);
    line-height: rem(24px);
    transition: all 0.3s;

    @media (max-width: $tabletM) {
      font-size: rem(16px);
      padding-bottom: rem(3px);
      padding-top: rem(3px);
    }

    &:hover {
      color: $light-blue;
    }
  }

  // &__nav-item:last-of-type &__nav-link {
  //     border-bottom: none;
  // }

  &__title {
    transform: rotate(-90deg);
    white-space: nowrap;
    height: 100%;
    padding-top: rem(40px);
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 10rem;
    height: auto;
    transform-origin: 0 0;
    transform: rotate(-90deg) translateX(-64%);
    left: 0;

    @media (max-width: $desktop) {
      span {
        font-size: 2rem !important;
        line-height: 2.2rem !important;
      }
    }

    @media (max-width: $tablet) {
      position: relative;
      transform: none;
      padding-top: 0;
      display: flex;
      justify-content: flex-end;
      white-space: nowrap;
    }
  }

  &__title span {
    font-size: rem(44px);
    line-height: rem(48px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
    color: #252e3a;
  }

  &__contacts {
    margin-bottom: rem(48px);

    @media (max-width: $tabletM) {
      margin-bottom: rem(23px);
    }
  }

  &__lang {
    @media (max-width: $tablet) {
      margin-bottom: rem(40px);
    }
  }
}
