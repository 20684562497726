.mfp-bg {
    opacity: 1;
    max-height: 100vh;
}
img.mfp-img {
    padding: 10% 0;

    @media (max-width: $tablet) {
        padding: 15% 0;
    }

    @media (max-width: $mobile) {
        padding: 20% 0;
    }
}

.mfp-figure:after {
    display: none;
}

.mfp-content {
    position: static;
    transition: opacity 0.25s ease-in;
    opacity: 0;

    &:before {
        position: absolute;
        top: rem(40px);
        left: rem(80px);
        z-index: 1;
        width: rem(40px);
        height: rem(46px);
        background-image: url(../img/icons/logo_small.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        content: '';

        @media (max-width: $desktop) {
            top: rem(33px);
            left: rem(72px);
        }

        @media (max-width: $tablet) {
            top: rem(25px);
            left: rem(46px);
        }

        @media (max-width: $mobile) {
            top: rem(16px);
            left: rem(16px);
            width: rem(28px);
            height: rem(32px);
        }
    }
}

.mfp-container {
    padding: 0 !important;

    &:before {
        display: none;
    }
}

.mfp-image-holder .mfp-close, .mfp-close-btn-in .mfp-close {
    top: rem(52px);
    right: rem(80px);
    width: rem(24px);
    height: rem(24px);
    background-image: url(../img/icons/cross.svg);
    background-size: 100%;
    font-size: 0;
    opacity: 1;

    @media (max-width: $desktop) {
        top: rem(44px);
        right: rem(72px);
    }

    @media (max-width: $tablet) {
        top: rem(37px);
        right: rem(50px);
    }

    @media (max-width: $mobile) {
        top: rem(20px);
        right: rem(16px);
    }
}

.mfp-zoom-out-cur {
    cursor: default;
}
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: pointer;
}

.mfp-wrap {
    overflow: hidden !important;
    position: fixed !important;
    top: 0 !important;
}

.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}

.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

.mfp-preloader {
    width: rem(200px);
    height: rem(200px);
    margin: rem(-100px) auto 0;
    font-size: 0;
    background-image: url(../img/icons/spinner.svg);
    background-size: rem(200px);
}
