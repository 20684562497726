.contacts-page .contacts {
  padding-top: rem(180px);

  @media (max-width: $desktop) {
    padding-top: rem(100px);
  }

  @media (max-width: $tablet) {
    padding-top: rem(30px);
  }

  @media (max-width: $mobile) {
    padding-top: rem(20px);
  }
}
