@font-face {
    font-family: 'TTInterphases';
    src: url('../fonts/TTInterphases-Regular.woff2') format('woff2'),
        url('../fonts/TTInterphases-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TTInterphases DemiBold';
    src: url('../fonts/TTInterphases-DemiBold.woff2') format('woff2'),
        url('../fonts/TTInterphases-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@mixin interface() {
    font-family: 'TTInterphases', Arial, Helvetica, sans-serif;
}

@mixin interface-bold() {
    font-family: 'TTInterphases DemiBold', Arial, Helvetica, sans-serif;
}

body {
    @include interface();
    font-weight: normal;
    color: #ffffff;
}