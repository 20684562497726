@function path($path) {
  @return '..' + $path;
}

@mixin button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

@mixin list-reset {
   margin: 0;
   padding: 0;
   list-style: none;
}

@mixin reset {
  margin: 0;
  padding: 0;
}

@mixin below($width, $orientation: 'width') {
  @media screen and (max-#{$orientation}: $width) {
      @content;
  }
}

@mixin above($width, $orientation: 'width') {
  @media screen and (min-#{$orientation}: $width) {
      @content;
  }
}

@mixin between($minWidth, $maxWidth, $orientation: 'width') {
  @media screen and (min-#{$orientation}: $minWidth) and (max-#{$orientation}: $maxWidth) {
      @content;
  }
}

@function rem($num) {
  $strip-unit: $num / ($num * 0 + 1);
  @return $strip-unit / 16 * 1rem;
}

// @function rem($pixels) {
//   @return #{$pixels / 16px}rem;
// }