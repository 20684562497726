.project-content {
  position: relative;
  width: 100%;
  padding-top: rem(192px);

  @media (max-width: $mobile) {
    padding-top: rem(80px);
  }

  &__wrapper {
    padding-bottom: rem(350px);
    margin-left: rem(200px);
    margin-right: rem(80px);

    @media (max-width: $desktop) {
      margin-left: rem(160px);
      margin-right: rem(72px);
      padding-bottom: rem(300px);
    }

    @media (max-width: $tablet) {
      margin: 0 rem(48px);
      padding-bottom: rem(100px);
    }

    @media (max-width: $mobile) {
      margin: 0;
      padding-bottom: rem(100px);
    }
  }

  &__title {
    @include interface-bold;

    position: absolute;
    top: rem(27px);
    left: rem(60px);
    display: block;
    font-size: rem(16px);
    line-height: rem(20px);
    text-transform: uppercase;
    transform: rotate(-90deg);
    animation: animation-name 1s ease-out;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__more {
    @include interface-bold;
    
    padding: 0;
    margin: 0 rem(16px);

    font-size: rem(32px);
    line-height: rem(40px);
    text-transform: lowercase;
    border: none;
    background-color: transparent;
    color: $blue;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      border: none;
      background-color: transparent;
      color: $light-blue;
    }
  }
}
