
.about {
	&__inner {
		position: relative;
		padding: rem(176px) rem(200px) rem(128px);
		font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
		color: #fff;

		@media (max-width: $desktop) {
			padding: rem(194px) rem(72px) rem(130px);
		}

		@media (max-width: $tablet) {
			padding: rem(194px) rem(48px) rem(85px);
		}

		@media (max-width: $mobile) {
			padding: rem(80px) rem(16px) rem(48px);
		}
	}

	&__ttl {
		margin: 0;
		font-weight: 600;
		font-size: rem(64px);
		line-height: rem(72px);
		letter-spacing: rem(0.8px);

		@media (max-width: $mobile) {
			font-size: rem(34px);
			line-height: rem(40px);
		}

		.article & {
			font-size: rem(56px);
			line-height: rem(64px);

			@media (max-width: $mobile) {
				font-size: rem(24px);
				line-height: rem(32px);
			}
		}

		&--billing {
			min-width: rem(1300);
			letter-spacing: rem(1.3px);

			@media (max-width: $desktop) {
				min-width: auto;
				max-width: rem(1200);
			}

			@media (max-width: $tablet) {
				max-width: rem(1300);
			}
		}

		&--furniture {
			letter-spacing: rem(1.3px);
		}
	}

	&__url {
		display: block;
		margin-top: rem(15px);
		font-size: rem(24px);
		line-height: rem(32px);
		color: #1F72FA;

		@media (max-width: $mobile) {
			font-size: rem(18px);
			line-height: rem(24px);
		}
	}

	&__description {
		margin-top: rem(80px);
		margin-bottom: 0;
		font-weight: 600;
		font-size: rem(44px);
		line-height: rem(48px);
		letter-spacing: rem(0.8px);

		@media (max-width: $tablet) {
			margin-top: rem(64px);
		}

		@media (max-width: $mobile) {
			margin-top: rem(32px);
			font-size: rem(26px);
			line-height: rem(32px);
		}

		.article & {
			font-size: rem(32px);
			line-height: rem(40px);
			margin-top: 48px;

			@media (max-width: $mobile) {
				margin-top: rem(32px);
				font-size: rem(20px);
				line-height: rem(24px);
			}
		}

		&--billing {
			letter-spacing: rem(0.845px);
		}

		&--furniture {
			letter-spacing: rem(0.95px);
		}
	}

	&__side {
		position: absolute;
		top: rem(441px);
		left: rem(91px);
		width: rem(250);
		font-weight: 600;
		font-size: rem(16px);
		line-height: rem(20px);
		text-transform: uppercase;
		text-align: right;
		letter-spacing: rem(0.8px);
		transform: rotate(-90deg) !important;
		transform-origin: left top;
		animation: animation-side 2s ease-out;

		@media (max-width: $desktop) {
			display: none;
		}
	}
	&__cover {
		overflow-x: hidden;
	}

	&__img {
		display: block;
		width: 100%;

		@media (max-width: $desktop) {
			width: 146%;
			margin-left: -23%;
		}
	}
}

.info {
	overflow: hidden;

	&__inner {
		margin-top: rem(128px);
		padding: 0 rem(320px);
		color: #fff;

		@media (max-width: $desktop) {
			padding: 0 rem(72px);
		}

		@media (max-width: $tablet) {
			margin-top: rem(80px);
			padding: 0 rem(48px);
		}

		@media (max-width: $mobile) {
			margin-top: rem(32px);
			padding: 0 rem(16px);
		}

		&--mt-min {
			margin-top: rem(54px);
		}

		&--mb2 {
			margin-bottom: rem(32px);
		}

		&_bg1 {
			padding-top: rem(61px);
			padding-bottom: rem(61px);
			background-color: #E01E5A;

			@media (max-width: $mobile) {
				padding-top: 0;
				padding-bottom: 0;
				background: none;
			}
		}

		&_bg2 {
			position: relative;
			padding-top: rem(61px);
			padding-bottom: rem(61px);

			&:before{
				content:"";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 15rem;
				right: 15rem;
				background-color: #1F72FA;
				border-radius: 0.75rem;
				z-index: -1;
			}

			@media (max-width: $desktop) {
				&::before {
					border-radius: 0;
					left: 0;
					right: 0;
				}
			}

			@media (max-width: $mobile) {
				padding-top: 0;
				padding-bottom: 0;
				background: none;

				&:before{
					content: none;
				}
			}
		}
	}

	&__ttl {
		margin-top: 0;
		margin-bottom: rem(32px);
		font-weight: 600;
		font-size: rem(34px);
		line-height: rem(40px);
		letter-spacing: rem(0.8px);

		@media (max-width: $mobile) {
			margin-bottom: rem(16px);
			font-size: rem(20px);
			line-height: rem(24px);
		}
	}

	&__text,
	&__list {
		margin: 0;
		font-size: rem(24px);
		line-height: rem(32px);

		@media (max-width: $mobile) {
			font-size: rem(18px);
			line-height: rem(24px);
		}

		& + .info__text {
			margin-top: rem(20px);

			&--mt2 {
				margin-top: rem(32px);
			}
		}

		& + .info__list {
			margin-top: rem(11px);
		}
	}

	&__list {
		counter-reset: item;
		font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

		li {
			&:before {
				counter-increment: item;
				content: counter(item) '. ';
				color: #1F72FA;
			}
		}

		&_marker {
			li {
				margin-top: rem(10);
				&:before {
					display: inline-block;
					vertical-align: middle;
					width: rem(12px);
					height: rem(12px);
					border-radius: 50%;
					margin-right: rem(14px);
					background-color: #16CFFE;
					content: '';
				}
			}
		}
	}

	&__img-container {
		display: flex;
		justify-content: space-between;
		margin-top: rem(80px);
		padding: 0 rem(200px);

		@media (max-width: $desktop) {
			padding: 0 rem(72px);
		}

		@media (max-width: $tablet) {
			padding: 0 rem(48px);
		}

		@media (max-width: $mobile) {
			display: block;
			margin-top: rem(48px);
			padding: 0 rem(16px);
		}

		& + .info__img-container {
			margin-top: rem(27px);

			@media (max-width: $mobile) {
				margin-top: rem(16px);
			}
		}

		&--mt0 {
			margin-top: rem(16px);
		}

		&--mb3 {
			margin-bottom: rem(128px);

			@media (max-width: $mobile) {
				margin-bottom: rem(48px);
			}
		}

		&--billing {
			margin-top: rem(45px);
			padding-left: rem(165px);
		}

		&--furniture-mt2 {
			margin-top: rem(56px);
		}
	}

	&__img-wrap {
		position: relative;
		z-index: 1;
		width: 100%;
		overflow: hidden;
		border-radius: rem(12px);
		box-shadow: rem(-4px) rem(4px) rem(60px) rgba(0, 0, 0, 0.25);

		@media (max-width: $mobile) {
			border-radius: rem(4px);
			box-shadow: 0 rem(3.5px) rem(35px) rgba(0, 0, 0, 0.16);
		}

		&_no-shadow {
			box-shadow: none;
		}

		&_no-border {
			border-radius: 0;
		}

		&_mt30 {
			margin-top: rem(30);
		}

		&_mb30 {
			margin-bottom: rem(30);
		}

		&_third {
			width: 33%;
			min-width: 375px;

			@media (max-width: $mobile) {
				min-width: 0;
				width: 100%;
			}
		}

		&_center {
			margin: 0 auto;
		}
	}

	&__img-bg {
		position: relative;
		z-index: 0;
		width: 100%;

		&:before,
		&:after {
			position: absolute;
			display: block;
			content: '';
		}

		&_tk1 {
			&:before {
				top: rem(-99px);
				left: rem(-92px);
				width: rem(162px);
				height: rem(199px);
				background-image: url(../img/project/timekeeper_bg1.png);
				background-size: 100%;

				@media (max-width: $desktop) {
					left: rem(-60px);
				}

				@media (max-width: $tablet) {
					display: none;
				}
			}

			&:after {
				top: rem(-160px);
				left: rem(243px);
				width: rem(497px);
				height: rem(497px);
				background-image: url(../img/project/timekeeper_bg2.png);
				background-size: 100%;

				@media (max-width: $tablet) {
					left: rem(120px);
					width: rem(400px);
					height: rem(400px);
				}

				@media (max-width: $mobile) {
					top: rem(-110px);
					left: rem(15px);
					width: rem(260px);
					height: rem(260px);
				}
			}
		}

		&_tk2 {
			&:before {
				top: rem(-25px);
				right: rem(-218px);
				width: rem(406px);
				height: rem(163px);
				background-image: url(../img/project/timekeeper_bg3.png);
				background-size: 100%;

				@media (max-width: $desktop) {
					display: none;
				}
			}
		}

		&_tk3 {
			&:before {
				top: rem(-30px);
				left: rem(-175px);
				width: rem(203px);
				height: rem(151px);
				background-image: url(../img/project/timekeeper_bg4.png);
				background-size: 100%;

				@media (max-width: $desktop) {
					top: rem(-47px);
					left: rem(-135px);
				}

				@media (max-width: $mobile) {
					display: none;
				}
			}
		}

		&_br1 {
			&:before {
				right: rem(-150);
				top: rem(-300px);
				width: rem(360px);
				height: rem(360px);
				border: 2px dashed #F74A14;
				border-radius: 50%;
				content: '';

				@media (max-width: $tablet) {
					display: none;
				}
			}
		}

		&_br2 {
			&:before {
				left: rem(-160);
				top: rem(-230px);
				width: rem(304px);
				height: rem(304px);
				border-radius: 50%;
				background-color: #F74A14;
				content: '';

				@media (max-width: $tablet) {
					display: none;
				}
			}
		}

		&_br3 {
			&:before {
				left: 0;
				right: 0;
				top: rem(-430px);
				margin: auto;
				width: rem(560px);
				height: rem(560px);
				border-radius: 50%;
				background-color: #252D3A;
				content: '';

				@media (max-width: $tablet) {
					display: none;
				}
			}

			&:after {
				left: 0;
				right: 0;
				top: rem(-370px);
				margin: auto;
				width: rem(428px);
				height: rem(428px);
				border: 2px dashed #F74A14;
				border-radius: 50%;
				content: '';

				@media (max-width: $tablet) {
					display: none;
				}
			}
		}
	}

	&__img-cell {
		&_g2 {
			width: 49%;
		}

		&_g3 {
			width: 48.6%;
		}

		&_g4 {
			width: 57.8%;
		}

		&_g5 {
			width: 39.8%;
		}

		&_w2, &_b2 {
			width: 49.4%;
		}

		&_w3, &_b3 {
			width: 49.4%;
		}

		&_br1 {
			width: 50.5%;
		}

		&_br2 {
			width: 47.5%;
		}

		&_g2, &_g3, &_g4, &_g5, &_w2, &_w3, &_b2, &_b3, &_br1, &_br2 {
			@media (max-width: $mobile) {
				width: 100%;
			}
		}

		@media (max-width: $mobile) {
			& + .info__img-cell {
				margin-top: rem(16px);
			}
		}
	}

	&__img {
		display: block;
		width: 100%;
	}

	&__big-img {
		display: block;
		margin-top: rem(128px);
		width: 100%;

		@media (max-width: $tablet) {
			margin-top: rem(80px);
		}

		@media (max-width: $mobile) {
			margin-top: rem(32px);
		}

		&--mt0 {
			margin-top: rem(56px);
		}
	}
}

.review {
	padding: 0 rem(320px);

	@media (max-width: $desktop) {
		padding-left: rem(72px);
		padding-right: rem(72px);
	}

	@media (max-width: $tablet) {
		padding-left: rem(48px);
		padding-right: rem(48px);
	}

	@media (max-width: $mobile) {
		padding-left: rem(16px);
		padding-right: rem(16px);
	}

	&__inner {
		position: relative;
		padding: rem(128px) 0;
		text-align: center;
		font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
		color: #fff;

		@media (max-width: $desktop) {
			padding-left: rem(88px);
			padding-right: rem(88px);
		}

		@media (max-width: $tablet) {
			padding-top: rem(80px);
			padding-left: 0;
			padding-right: 0;
		}

		@media (max-width: $mobile) {
			padding-top: rem(48px);
			padding-bottom: rem(48px);
		}

		&:before {
			position: absolute;
			top: rem(128px);
			left: 0;
			content: '';
			display: block;
			width: rem(120px);
			height: rem(80px);
			background-image: url(../img/icons/quote.svg);
			background-size: 100%;

			@media (max-width: $desktop) {
				left: rem(88px);
			}

			@media (max-width: $tablet) {
				top: rem(80px);
				left: 0;
			}

			@media (max-width: $mobile) {
				display: none;
			}
		}
	}

	&__userpic {
		display: inline-block;
		vertical-align: top;
		width: rem(112px);
		height: rem(112px);
		border-radius: 50%;
		overflow: hidden;

		@media (max-width: $mobile) {
			width: rem(64px);
			height: rem(64px);
		}
	}

	&__img {
		display: block;
		width: 100%;
	}

	&__name {
		margin-top: rem(16px);
		font-size: rem(18px);
		line-height: rem(24px);
		letter-spacing: rem(0.3px);

		@media (max-width: $mobile) {
			margin-top: rem(8px);
		}
	}

	&__position {
		font-size: rem(18px);
		line-height: rem(24px);
		opacity: 0.3;
	}

	&__logo {
		display: inline-block;
		vertical-align: top;
		margin-top: rem(36px);

		@media (max-width: $mobile) {
			margin-top: rem(28px);
		}

		&_gectaro {
			width: rem(78px);

			@media (max-width: $mobile) {
				width: rem(58px);
			}
		}

		&_sozvezdie {
			width: rem(230px);

			@media (max-width: $mobile) {
				width: rem(160px);
			}
		}

		&_multifoto {
			width: rem(159px);

			@media (max-width: $mobile) {
				width: rem(120px);
			}
		}

		&_furniture {
			width: rem(162px);

			@media (max-width: $mobile) {
				width: rem(120px);
			}
		}

		&_brosko {
			width: rem(62px);

			@media (max-width: $mobile) {
				width: rem(46px);
			}
		}
	}

	&__text {
		margin-top: rem(35px);
		font-size: rem(24px);
		line-height: rem(32px);

		@media (max-width: $mobile) {
			margin-top: rem(28px);
			font-size: rem(20px);
			line-height: rem(24px);
		}
	}

	&__link-wrap {
		margin-top: rem(32px);
	}

	&__link {
		font-size: rem(18px);
		line-height: rem(24px);
		color: #1F72FA;
	}
}

.technologies {
	padding: 0 rem(320px);

	@media (max-width: $desktop) {
		padding-left: rem(72px);
		padding-right: rem(72px);
	}

	@media (max-width: $tablet) {
		padding-left: rem(48px);
		padding-right: rem(48px);
	}

	@media (max-width: $mobile) {
		padding-left: rem(16px);
		padding-right: rem(16px);
	}

	&__inner {
		display: flex;
		padding: rem(80px) 0;
		border-top: 1px solid rgba(254, 254, 254, 0.1);
		border-bottom: 1px solid rgba(254, 254, 254, 0.1);
		font-size: rem(18px);
		line-height: rem(24px);

		@media (max-width: $mobile) {
			display: block;
			padding: rem(48px) 0;
		}
	}

	&__title {
		display: block;
		width: rem(160px);
		letter-spacing: rem(0.4px);
		color: #4D5460;

		@media (max-width: $mobile) {
			width: 100%;
			margin-bottom: rem(8px);
		}
	}

	&__list {
		display: block;
		width: rem(800px);

		@media (max-width: $desktop) {
			width: rem(896px);
		}

		@media (max-width: $tablet) {
			width: rem(512px);
		}

		@media (max-width: $mobile) {
			width: 100%;
		}
	}

	&__item {
		margin-right: rem(5px);
		letter-spacing: rem(0.1px);

		&:not(:last-child):after {
			content: ',';
		}
	}
}

.slider {
	margin-top: rem(80px);
	padding: rem(125px) rem(320px) rem(200px);

	@media (max-width: $desktop) {
		padding-top: rem(80px);
		padding-left: rem(160px);
		padding-right: rem(160px);
	}

	@media (max-width: $tablet) {
		padding-top: rem(64px);
		padding-left: rem(48px);
		padding-right: rem(48px);
	}

	@media (max-width: $mobile) {
		margin-top: rem(32px);
		padding: rem(32px) rem(16px) rem(96px);
	}

	&_gectaro {
		background-color: #105AD2;
	}

	&_sozvezdie {
		background-color: #067256;
	}

	&_wikiworks {
		background-color: #B4965A;
		padding-bottom: rem(100px);

		@media (max-width: $mobile) {
			padding-bottom: rem(66px);
		}
	}

	&_wiki {
		background-color: #CA2525;
		padding-bottom: rem(100px);

		@media (max-width: $mobile) {
			padding-bottom: rem(66px);
		}
	}

	&_yelm {
		background-color: #3A9CFF;
		padding-bottom: rem(100px);

		@media (max-width: $mobile) {
			padding-bottom: rem(66px);
		}
	}

	&_brosko {
		background-color: #F74A14;
	}

	&_reinnolc {
		background-color: #2a2929;
	}

	&_livenhouse {
		background-color: #5123F9;
	}

	&__item {
		border-radius: rem(12px);
		overflow: hidden;

		@media (max-width: $mobile) {
			border-radius: rem(4px);
		}
	}

	&__img {
		display: block;
		width: 100%;
	}

	&__counter {
		margin-top: rem(114px);
		text-align: center;
		font-size: rem(18px);
		line-height: rem(24px);
		font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

		@media (max-width: $desktop) {
			margin-top: rem(90px);
		}

		@media (max-width: $mobile) {
			margin-top: rem(32px);
		}

		span {
			margin: 0 rem(32px);
			font-weight: 600;
		}
	}

	.slick-arrow {
		top: 50%;
		width: rem(47px);
		height: rem(23px);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		background-color: transparent;

		transition: background 0.5s ease-out;
		outline: none;

		@media (max-width: $tablet) {
			top: auto;
			bottom: rem(-132px);
		}

		@media (max-width: $mobile) {
			bottom: rem(-72px);
		}

		&.slick-next {
			right: rem(-168px);
			background-image: url(../img/icons/slider-arr-r.svg);

			@media (max-width: $desktop) {
				right: rem(-90px);
			}

			@media (max-width: $tablet) {
				right: 0;
			}
		}

		&.slick-prev {
			left: rem(-168px);
			background-image: url(../img/icons/slider-arr-l.svg);

			@media (max-width: $desktop) {
				left: rem(-90px);
			}

			@media (max-width: $tablet) {
				left: 0;
			}
		}

		&:before {
			display: none;
		}
	}

	.slick-slide {
		opacity: 0;
		transition: opacity 0.5s ease-in;

		&.slick-active {
			opacity: 1;
		}
	}

	.slider__numb {
		bottom: rem(-148px);

		@media (max-width: $desktop) {
			bottom: rem(-120px);
		}

		@media (max-width: $mobile) {
			bottom: rem(-60px);
		}

		&:after {
			background-color: #fff;
		}
	}
}

.project-content_more {
	margin-top: rem(128px);
	padding-top: 0 !important;

	@media (max-width: $tablet) {
		margin-top: rem(80px);
	}

	@media (max-width: $mobile) {
		margin-top: rem(48);
	}

	.project-content__wrapper {
		padding-bottom: rem(80px);
	}

	.project-content__title {
		top: rem(60px);
		left: rem(46px);

		@media (max-width: $desktop) {
			left: rem(20px);
		}

		@media (max-width: $mobile) {
			position: static;
			display: block;
			margin-bottom: rem(32px);
			font-size: rem(32px);
			line-height: rem(40px);
			text-transform: none;
			text-align: center;
			font-weight: 600;
			transform: none;
			animation: none;
		}
	}

	.project-list__item:nth-child(even) {
		top: 0;
	}
}

@keyframes animation-side {
	0% {
		opacity: 0;
		transform: rotate(-90deg) translateY(-120px);
	}
	100% {
		opacity: 1;
		transform: rotate(-90deg) translateY(0);
	}
}
