.simple-tooltip {
    $this: &;
    position: relative;
    color: $blue;
    display: inline-block;
    cursor: pointer;

    &:hover {
        color: $light-blue;
    }
}

.ui-tooltip {
    padding: 10px 16px;
    position: absolute;
    z-index: 9999;
    max-width: 277px;
    border-radius: 12px;

    @media (max-width: $mobile) {
        max-width: 228px;
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: -15px;
        left: 15px;
        border: 10px solid transparent;
        border-right: 10px solid #1F72FA;
        border-bottom: 10px solid #1F72FA;

        @media (max-width: $mobile) {
            content: none;
        }
    }
}

.ui-widget-content {
    background-color: #1F72FA;
    color: #ffffff;
    font-size: 17px;
    line-height: 1.2;
}

.ui-widget-content a {
    color: #333333;
}