@keyframes rotate {
    from {
        transform:rotate(360deg);
    }
    to {
        transform:rotate(0deg);
    }
}

@keyframes rotateWordsFirst {
    0% {
        opacity: 1;
        top: 0;
    }
    3% {
        opacity: 1;
        top: 0;
    }
    25% {
        opacity: 1;
        top: 0;
    }
    30% {
        opacity: 1;
        top: 200%;
    }
    31% {
        opacity: 0;
    }
    44% {
        opacity: 0;
        top: -200%;
    }
    100% {
        display: none;
        opacity: 0;
        top: -200%;
    }
}

@keyframes rotateWords {
    0% {
        opacity: 1;
        top: -200%;
    }
    3% {
        opacity: 1;
        top: 0;
    }
    25% {
        opacity: 1;
        top: 0;
    }
    30% {
        opacity: 1;
        top: 200%;
    }
    31% {
        opacity: 0;
    }
    44% {
        opacity: 0;
        top: -200%;
    }
    100% {
        opacity: 1;
        top: -200%;
    }
}
