// hero

.hero {
  position: relative;

  @media (max-width: $tablet) {
    min-height: rem(960px);
  }

  @media (max-width: $mobile) {
    align-items: flex-start;
    padding: 0 rem(16px);
    min-height: auto;
  }

  &__title {
    width: rem(1200px);
    margin: rem(54px) 0 rem(63px) 0;

    @include interface-bold();
    font-size: rem(108px);
    line-height: rem(104px);
    text-transform: lowercase;
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    @media (max-width: $desktop) {
      width: rem(1056px);
      margin: rem(52px) 0 0;
    }

    @media (max-width: $tablet) {
      width: rem(672px);
      margin: rem(122px) auto rem(225px);

      font-size: rem(76px);
      line-height: rem(72px);
    }

    @media (max-width: $mobile) {
      font-size: rem(34px);
      line-height: rem(40px);
      margin-bottom: rem(111px);
      margin-top: rem(114px);
      width: 100%;
    }
  }

  &__animate {
    position: absolute;
    top: 0;
    color: $blue;

    &_second,
    &_third,
    &_fourth,
    &_first_inf {
      top: -200%;
    }

    &_first {
      animation: rotateWordsFirst 12s ease-out 0s;
      animation-fill-mode: forwards;
    }

    &_second {
      animation: rotateWords 12s ease-out 3s infinite;
    }

    &_third {
      animation: rotateWords 12s ease-out 6s infinite;
    }

    &_fourth {
      animation: rotateWords 12s ease-out 9s infinite;
    }

    &_first_inf {
      animation: rotateWords 12s ease-out 12s infinite;
    }
  }

  &__wrapper {
    position: relative;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  &__title-wrap {
    position: relative;
    top: rem(11);
    height: rem(115px);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: $tablet) {
      height: rem(79px);
      top: rem(5);
    }

    @media (max-width: $mobile) {
      height: rem(40px);
      top: 0;
    }
  }

  &__subtitle {
    max-width: rem(485px);
    padding-left: rem(5px);

    font-size: rem(24px);
    line-height: rem(32px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    @media (max-width: $desktop) {
      max-width: rem(528px);
      padding-left: 0;
    }

    @media (max-width: $tablet) {
      margin-bottom: rem(72px);
    }

    @media (max-width: $mobile) {
      font-size: rem(18px);
      line-height: rem(24px);
      letter-spacing: -0.01em;
      margin-bottom: rem(30px);
    }
  }

  &__circle {
    position: absolute;
    right: rem(-95px);
    bottom: rem(-95px);
    z-index: 0;

    height: rem(493px);
    width: rem(487px);

    opacity: 0;

    @media (max-width: $desktop) {
      right: rem(-35px);
      bottom: rem(-35px);

      width: rem(433px);
      height: rem(439px);
    }

    @media (max-width: $tablet) {
      bottom: rem(90px);
      right: rem(-130px);
    }

    @media (max-width: $mobile) {
      bottom: rem(45px);

      width: rem(274px);
      height: rem(277px);
    }

    &.aos-animate {
      opacity: 0.1 !important;

      @media (max-width: $tablet) {
        opacity: 0.2 !important;
      }
    }
  }

  &__circle-img {
    height: 100%;
    width: 100%;

    animation: rotate 30s linear 0s infinite;
  }

  &__func {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: rem(1200px);
    margin: 0 auto;

    @media (max-width: $desktop) {
      width: rem(1056px);
      margin-top: rem(5px);
      padding-left: rem(88px);
    }

    @media (max-width: $tablet) {
      padding-left: rem(272px);
      padding-right: rem(56px);
      width: 100%;
      margin: 0;
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    @media (max-width: $mobile) {
      padding: 0;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;

    @media (max-width: $mobile) {
      justify-content: flex-start;
      width: 100%;
    }
  }
}

// clients

.clients {
  @media (max-width: $tablet) {
    height: rem(580px);
    min-height: rem(650px);
    display: flex;
    flex-direction: column;
  }

  @media (max-width: $mobile) {
    height: auto;
    min-height: rem(180px);
  }

  &__sliders {
    margin-top: rem(96px);

    @media (max-width: $desktop) {
      margin-top: rem(64px);
    }

    @media (max-width: $tablet) {
      margin-top: rem(20px);
    }

    @media (max-width: $mobile) {
      margin-top: rem(15px);
    }
  }

  &__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: rem(40px);

    @media (max-width: $tablet) {
      width: 100%;
    }

    @media (max-width: $mobile) {
      padding-top: 0;
    }
  }

  &__all {
    display: flex;
    justify-content: center;
    margin-top: rem(-36px);
    margin-bottom: rem(200px);

    @media (max-width: $desktop) {
      margin-top: rem(-65px);
      margin-bottom: rem(136px);
    }

    @media (max-width: $tablet) {
      margin-top: rem(-156px);
      margin-bottom: rem(168px);
    }

    @media (max-width: $mobile) {
      margin-top: 0;
      margin-bottom: rem(65px);
    }
  }

  &__all-text {
    margin-right: rem(15px);

    @include interface-bold();
    font-size: rem(50px);
    line-height: rem(56px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
    color: $blue;

    transition: 0.5s;

    @media (max-width: $mobile) {
      font-size: rem(32px);
      line-height: rem(40px);
    }
  }

  &__all-text:hover {
    color: $light-blue;
  }

  &__content {
    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &__all span {
    @include interface-bold();
    font-size: rem(50px);
    line-height: rem(56px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    @media (max-width: $mobile) {
      font-size: rem(32px);
      line-height: rem(40px);
    }
  }
}

.client-slider {
  display: block;

  &.str_wrap {
    background: none !important;
  }

  &__item {
    height: rem(128px);
    flex-shrink: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: rem(24px) rem(64px);
    //padding: rem(24px) 0 /*rem(64px)*/;
    //width: rem(280px);

    @media (max-width: $mobile) {
      height: rem(76px);
    }
  }

  // &__pic {}

  &__img {
    background-size: contain;
    display: block;
  }
}

// tags

.tags {
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: $mobile) {
    height: rem(300px);
  }

  &__sliders {
    margin-top: rem(112px);

    @media (max-width: $desktop) {
      margin-top: rem(88px);
    }

    @media (max-width: $mobile) {
      margin-top: rem(32px);
    }
  }

  &__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $tablet) {
      width: 100%;
    }

    .section__subtitle {
      margin-top: rem(-38px);

      @media (max-width: $desktop) {
        margin-top: 0;
      }

      @media (max-width: $tablet) {
        margin-top: rem(80px);
      }
    }
  }
}

.services {
  @media (max-width: $tablet) {
    padding-bottom: rem(200px);
  }
}

.tag-slider {
  margin-bottom: rem(80px);
  height: rem(60px);
  display: block;

  & li {
    display: inline-block;
  }

  @media (max-width: $mobile) {
    margin-bottom: rem(14px);
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &__text {
    @include interface-bold();
    font-size: rem(50px);
    line-height: rem(56px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    cursor: pointer;
    transition: 1s;

    @media (max-width: $mobile) {
      font-size: rem(32px);
      line-height: rem(40px);
    }
  }

  &__text:hover {
    color: $light-blue;
  }

  &__item {
    position: relative;

    padding: 0 rem(56px);
  }

  &__item::after {
    content: '';

    position: absolute;
    top: 50%;
    left: rem(-24px);

    display: block;
    height: 2px;
    width: rem(48px);

    background-color: $blue;
  }
}

// technology

.technology {
  //height: 100%;

  @media (max-width: $tablet) {
    min-height: rem(810px);
    margin-top: rem(75px);
  }

  @media (max-width: $mobile) {
    min-height: auto;
    margin-top: rem(30px);
  }

  &__wrapper {
    display: flex;
    height: 100%;

    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &__tech-list {
    width: 50%;
    display: flex;
    align-items: center;
    padding-top: 90px;

    @media (max-width: $tablet) {
      width: 100%;
      padding: 0 rem(48px);
    }

    @media (max-width: $mobile) {
      align-items: flex-start;
      padding: rem(35px) rem(15px) 0;
    }
  }
}

.tech-slider {
  width: 50%;
  height: 100%;

  background-color: $dark-blue;

  @media (max-width: $tablet) {
    display: none;
    width: 100%;
    height: 100%;
  }

  .owl-stage-outer {
    overflow: visible;
  }

  &__list {
    @media (max-width: $tablet) {
      width: 100%;
      height: 100%;
    }
  }

  &__item {
    display: flex;
    position: absolute;
    top: 50%;

    opacity: 0;
    transition: top 0.25s ease-out;

    width: 100%;
    height: 100%;
    align-items: center;
    padding-left: rem(200px);
    padding-right: rem(120px);

    @media (max-width: $desktop) {
      padding-left: rem(72px);
      padding-right: rem(88px);
      align-items: center;
      padding-bottom: 0;
    }

    @media (max-width: $tablet) {
      position: static;
      height: 100%;
      padding: 0 rem(48px);
      opacity: 1;
    }

    @media (max-width: $mobile) {
      padding: 0 rem(16px);
    }

    &.active {
      top: 0;
      opacity: 1;
    }
  }

  &__num {
    margin-bottom: rem(16px);

    font-size: rem(24px);
    line-height: rem(32px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    @media (max-width: $desktop) {
      margin-bottom: rem(26px);

      font-size: rem(18px);
      line-height: rem(24px);
    }

    @media (max-width: $tablet) and (orientation: landscape) {
      display: none;
    }

    @media (max-width: $mobile) {
      margin-bottom: rem(16px);
    }
  }

  &__item-content {
    margin-top: rem(-2px);
    font-size: rem(44px);
    line-height: rem(48px);
    height: rem(510px);

    @media (max-width: $desktop) {
      margin-top: rem(95px);
    }

    @media (max-width: $tablet) {
      padding-top: rem(190px);
      margin-top: 0;
    }

    @media (max-width: $mobile) {
      padding-top: rem(80px);
    }
  }

  &__ttl {
    display: none;

    @media (max-width: $tablet) {
      display: block;
      margin-bottom: rem(96px);
      font-size: rem(24px);
      line-height: rem(32px);
      font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
    }

    @media (max-width: $tablet) and (orientation: landscape) {
      margin-top: rem(30px);
      margin-bottom: rem(20px);
    }

    @media (max-width: $mobile) {
      margin-bottom: rem(32px);
      font-size: rem(18px);
      line-height: rem(24px);
    }
  }

  &__text {
    margin-bottom: rem(130px);

    @include interface-bold();
    font-size: rem(28px);
    line-height: rem(36px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    @media (max-width: $desktop) {
      margin-bottom: rem(70px);
      font-size: rem(24px);
      line-height: rem(30px);
    }

    @media (max-width: $tablet) and (orientation: landscape) {
      font-size: rem(22px);
      line-height: rem(26px);
    }

    @media (max-width: $mobile) {
      font-size: rem(16px);
      line-height: rem(20px);
    }

    &--with-arrow {
      margin-bottom: rem(46px);

      @media (max-width: $tablet) {
        margin-bottom: rem(32px);
      }

      @media (max-width: $mobile) {
        margin-bottom: rem(4px);
      }
    }
  }

  &__tags {
    display: flex;
    display: none;
  }

  &__tags-item {
    margin-right: rem(25px);
  }

  &__tags-link {
    font-size: rem(14px);
    line-height: rem(24px);
  }

  .owl-nav {
    @media (max-width: $tablet) {
      position: absolute;
      bottom: rem(64px);
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      padding: 0 rem(48px);
    }

    @media (max-width: $mobile) {
      bottom: rem(64px);
      padding: 0 rem(16px);
    }
  }

  .owl-next,
  .owl-prev {
    width: rem(48px);
    height: rem(24px);

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;

    transition: 0.5s;
    outline: none;

    & span {
      display: none;
    }
  }

  .owl-next {
    background-image: url(../img/icons/slider-arr-r.svg);
  }

  .owl-prev {
    background-image: url(../img/icons/slider-arr-l.svg);
  }

  .owl-next.disabled,
  .owl-prev.disabled {
    opacity: 0.5;
  }

  .owl-item {
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.active {
      opacity: 1;
    }
  }

  .slider__numb {
    @media (max-width: $tablet) {
      bottom: rem(65px);
    }

    @media (max-width: $mobile) {
      bottom: rem(64px);
    }

    &:after {
      background-color: #fff;
    }
  }
}

.tech-slider-popup {
  min-height: 100vh;

  .tech-slider {
    display: block;
  }

  .mfp-content {
    height: 100%;
  }
}

.tech-list {
  margin-top: rem(30px);
  margin-left: rem(120px);
  border-top: 1px solid rgba(white, 0.1);
  width: rem(480px);
  display: flex;
  flex-direction: column;

  @media (max-width: $desktop) {
    width: rem(440px);
    margin-left: rem(88px);
    margin-top: 0;
  }

  @media (max-width: $tablet) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
  }

  &__item {
    padding-top: rem(16px);
    padding-bottom: rem(15px);

    border-bottom: 1px solid rgba(white, 0.1);

    font-size: rem(24px);
    line-height: rem(32px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    transition: border-bottom-color 0.25s ease-out;
    cursor: pointer;

    @media (max-width: $desktop) {
      padding-bottom: rem(16px);
    }

    @media (max-width: $tablet) {
      border-bottom-color: rgba(white, 0.2);
    }

    @media (max-width: $mobile) {
      padding: rem(13px) 0;
      font-size: rem(18px);
      line-height: rem(24px);
      letter-spacing: rem(-0.5px);
    }

    &:hover {
      border-bottom-color: $light-blue;
    }

    &.active {
      border-bottom-color: rgba(white, 0.1);

      .tech-list__link {
        color: $blue;

        @media (max-width: $tablet) {
          color: #fff;
        }
      }
    }
  }

  &__link {
    transition: color 0.25s ease-out;

    &:hover {
      text-decoration: none;
      color: $light-blue;
    }

    &_desktop {
      @media (max-width: $tablet) {
        display: none;
      }
    }

    &_mob {
      display: none;

      @media (max-width: $tablet) {
        display: block;
      }
    }
  }
}

// projects

.projects {
  height: 100% !important;

  & .fp-tableCell {
    height: 100% !important;
  }

  &__wrapper {
    height: 100%;

    @media (max-width: $tablet) {
      width: 100%;
      padding-top: rem(280px);
      padding-left: rem(48px);
      padding-right: rem(48px);
    }

    @media (max-width: $mobile) {
      padding: rem(68px) 0 0;
    }
  }
}

.proj-slider {
  height: 100%;

  &__container {
    display: flex;
  }

  & .left-side,
  & .right-side {
    width: 50%;
    overflow: hidden;
  }

  & .right-side {
    @media (max-width: $tablet) {
      display: none;
    }
  }

  & .left-side {
    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &__info {
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: $tablet) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: $mobile) {
      width: 100%;
    }

    &:hover {
      .proj-slider__num {
        color: $light-blue;
      }

      .proj-slider__title {
        color: $light-blue;
      }

      .project-list__text-block {
        transform: translateY(rem(-24px));
      }
    }
  }

  &__info-wrap {
    margin-left: rem(200px);
    height: rem(480px);

    @media (max-width: $desktop) {
      margin-left: rem(72px);
      height: auto;
    }

    @media (max-width: $tablet) {
      margin-left: 0;
    }

    @media (max-width: $mobile) {
      padding: 0 rem(16px);
    }
  }

  &__vis {
    margin: auto;
    height: rem(640px);

    @media (max-width: $desktop) {
      height: rem(448px);
    }
  }

  &__num {
    margin-top: rem(-16px);
    margin-bottom: rem(33px);
    font-size: rem(24px);
    line-height: rem(32px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
    transition: 0.5s;

    @media (max-width: $desktop) {
      margin-top: rem(-48px);
    }

    @media (max-width: $tablet) {
      display: none;
    }

    @media (max-width: $mobile) {
      display: block;
      margin-top: rem(-37px);
      margin-bottom: rem(1px);
      font-size: rem(18px);
      line-height: rem(24px);
    }
  }

  &__title {
    max-width: rem(600px);
    margin-bottom: rem(32px);
    transition: 0.5s;

    @include interface-bold();
    font-size: rem(64px);
    line-height: rem(72px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    @media (max-width: $desktop) {
      margin-bottom: rem(32px);
    }

    @media (max-width: $tablet) {
      margin-bottom: rem(16px);

      font-size: rem(50px);
      line-height: rem(56px);
    }

    @media (max-width: $mobile) {
      font-size: rem(34px);
      line-height: rem(40px);
    }
  }

  &__desc {
    margin-bottom: rem(46px);
    max-width: rem(360px);

    font-size: rem(24px);
    line-height: rem(32px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    @media (max-width: $tablet) {
      max-width: rem(450px);
      margin-bottom: rem(32px);
      font-size: rem(18px);
      line-height: rem(24px);
      letter-spacing: 0.01em;
      font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
      color: #4d5460;
    }

    @media (max-width: $mobile) {
      margin-bottom: rem(30px);
      width: 100%;
      color: #ffffff;
    }
  }

  &__arrow {
    width: rem(47px);
    height: rem(23px);
    display: inline-block;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='24' viewBox='0 0 48 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M43.5859 13L0 13V11L43.5857 11L34.2929 1.70718L35.7071 0.292969L46.7071 11.293L47.4142 12.0001L46.7071 12.7072L35.7071 23.7072L34.2929 22.293L43.5859 13Z' fill='%231F72FA'/%3E%3C/svg%3E%0A");

    transition: 0.5s;
  }

  &__arrow:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='24' viewBox='0 0 48 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M43.5859 13L0 13V11L43.5857 11L34.2929 1.70718L35.7071 0.292969L46.7071 11.293L47.4142 12.0001L46.7071 12.7072L35.7071 23.7072L34.2929 22.293L43.5859 13Z' fill='%2316CFFE'/%3E%3C/svg%3E%0A");
  }

  &__vis-wrap {
    position: relative;
    height: rem(640px);
    width: 100%;
    padding: rem(30px);

    @media (max-width: $desktop) {
      height: rem(448px);
    }
  }

  &__vis-img {
    height: 100%;
  }

  &__link {
    display: block;
  }

  &__pic {
    display: none;

    @media (max-width: $tablet) {
      display: block;
      width: rem(448px);
      height: rem(430px);
      margin-bottom: rem(34px);
    }

    @media (max-width: $mobile) {
      width: 100%;
      height: rem(307px);
      margin-bottom: rem(54px);
    }

    &_01 {
      background-color: #2f3ec2;
    }

    &_02 {
      background-color: #ca2525;
    }

    &_03 {
      background-color: #7e2420;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }
}

// blog

.blog {
  height: 100% !important;
}

// reviews

.reviews {
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    height: rem(895px);
  }

  @media (max-width: $mobile) {
    height: auto;
    padding-top: rem(105px);
  }

  &__wrapper {
    width: rem(1200px);
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: $desktop) {
      width: rem(1056px);
    }

    @media (max-width: $tablet) {
      width: 100%;
    }
  }
}

.reviews-slider {
  padding-bottom: rem(80px);

  @media (max-width: $desktop) {
    padding-bottom: rem(60px);
  }

  @media (max-width: $tablet) {
    margin-top: rem(-40px);
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__vis {
    margin-bottom: rem(18px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $mobile) {
      margin-bottom: rem(8px);
    }
  }

  &__photo {
    width: rem(112px);
    height: rem(112px);
    margin-bottom: rem(16px);

    border-radius: 50%;
    overflow: hidden;

    @media (max-width: $mobile) {
      width: rem(64px);
      height: rem(64px);
      margin-bottom: rem(8px);
    }
  }

  &__pic {
    width: 100%;
    height: 100%;
  }

  &__name {
    font-size: rem(18px);
    line-height: rem(24px);
    letter-spacing: 0.01em;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
  }

  &__desc {
    margin-bottom: rem(16px);

    font-size: rem(18px);
    text-align: center;
    line-height: rem(24px);
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
    color: #ffffff;
    opacity: 0.3;

    @media (max-width: $mobile) {
      margin-bottom: rem(8px);
    }
  }

  &__comp {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(120px);
    height: rem(64px);

    @media (max-width: $mobile) {
      width: rem(96px);
      height: rem(48px);
    }
  }

  &__comp-pic {
    width: auto;
    max-width: 100%;
    height: 100%;
    min-height: rem(64px);
  }

  &__text {
    width: rem(960px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    @media (max-width: $desktop) {
      width: rem(1054px);
    }

    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &__text-text {
    margin-bottom: rem(30px);
    width: 100%;
    text-align: center;
    font-size: rem(34px);
    line-height: rem(40px);

    @media (max-width: $tablet) {
      max-width: 90%;
    }

    @media (max-width: $mobile) {
      font-size: rem(20px);
      line-height: rem(24px);
    }
  }

  &__link {
    text-align: center;
    font-size: rem(18px);
    line-height: rem(24px);
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
    color: $blue;

    transition: 0.5s;
  }

  &__link:hover {
    color: $light-blue;
  }

  .slick-list:before {
    width: rem(120px);
    height: rem(80px);
    content: '';

    position: absolute;
    top: 0;
    left: rem(120px);

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='120' height='80' viewBox='0 0 120 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.07897 9.35432L9.08048 9.35275C14.4388 3.77775 21.0105 1 28.7858 1C36.709 1 43.1477 3.77874 48.2064 9.30921C53.2697 14.8688 55.8204 22.0135 55.8204 30.8551C55.8204 41.9472 51.5773 52.1685 42.974 61.5686C34.4702 70.8601 23.829 76.6508 11.0361 78.9442L10.9928 78.9145C18.4861 73.8835 22.7044 66.5999 23.6505 57.1524L22.9404 56.0942C15.5197 53.8882 10.0501 50.5845 6.43517 46.2459C2.83072 41.9198 1 36.481 1 29.8605C1 21.739 3.69403 14.9327 9.07897 9.35432ZM86.8543 57.1524L86.1443 56.0942C78.7236 53.8882 73.254 50.5845 69.639 46.2459C66.0346 41.9198 64.2039 36.481 64.2039 29.8605C64.2039 21.7368 66.8751 14.9312 72.2586 9.35431C77.6423 3.77723 84.191 1 91.9896 1C99.8886 1 106.328 3.77873 111.387 9.31019C116.45 14.8696 119 22.014 119 30.8551C119 41.9472 114.757 52.1685 106.154 61.5686C97.6494 70.8605 87.0319 76.6507 74.2641 78.9441L74.2191 78.9132C81.6906 73.8822 85.9083 66.5992 86.8543 57.1524Z' stroke='%231F72FA' stroke-width='2' stroke-linejoin='bevel'/%3E%3C/svg%3E%0A");

    @media (max-width: $desktop) {
      left: rem(88px);
    }

    @media (max-width: $tablet) {
      left: rem(50px);
    }

    @media (max-width: $mobile) {
      display: none;
    }
  }

  .slick-slide {
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.slick-active {
      opacity: 1;
    }
  }

  .slick-arrow {
    top: auto;
    bottom: rem(-12px);
    width: rem(47px);
    height: rem(23px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;

    transition: background 0.5s ease-out, filter 0.5s ease-out;
    outline: none;

    &.slick-next {
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='48' height='24' viewBox='0 0 48 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M43.5859 13L0 13V11L43.5857 11L34.2929 1.70718L35.7071 0.292969L46.7071 11.293L47.4142 12.0001L46.7071 12.7072L35.7071 23.7072L34.2929 22.293L43.5859 13Z' fill='%231F72FA'/%3E%3C/svg%3E%0A");

      &:hover {
        filter: brightness(1.5);
      }

      @media (max-width: $tablet) {
        right: rem(50);
      }

      @media (max-width: $mobile) {
        right: rem(20);
      }
    }

    &.slick-prev {
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='48' height='24' viewBox='0 0 48 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.41414 13L48 13V11L4.41429 11L13.7071 1.70718L12.2929 0.292969L1.29289 11.293L0.585785 12.0001L1.29289 12.7072L12.2929 23.7072L13.7071 22.293L4.41414 13Z' fill='%231F72FA'/%3E%3C/svg%3E%0A");

      &:hover {
        filter: brightness(1.5);
      }

      @media (max-width: $tablet) {
        left: rem(50);
      }

      @media (max-width: $mobile) {
        left: rem(20);
      }
    }

    &:before {
      display: none;
    }
  }

  .slider__numb.animation:after {
    animation-duration: 15s;
  }
}

.slider__numb {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  width: rem(120px);
  height: rem(24px);

  &.animation {
    &:after {
      animation: animation-numb 5s linear;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);

    height: 2px;
    width: rem(40px);

    background-color: rgba(#ffffff, 0.1);
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);

    height: 2px;
    width: rem(4px);

    background-color: #1f72fa;
  }
}

.slider__this,
.slider__total {
  width: rem(24px);
  height: rem(24px);
  display: inline-block;
  font-size: rem(18px);
  line-height: rem(24px);
  text-align: center;
  letter-spacing: 0.01em;
  font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
}

.slider__this {
  float: left;
}

.slider__total {
  float: right;
}

// contacts

.contacts {
  position: relative;

  @media (max-width: $tablet) {
    padding-top: rem(30px);
    padding-bottom: rem(195px);
  }

  @media (max-width: $mobile) {
    padding-top: rem(142px);
    padding-bottom: rem(120px);
  }

  &__wrap {
    width: rem(1200px);
    margin-left: rem(200px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    padding-top: 30px;

    @media (max-width: $desktop) {
      width: rem(1056px);
      margin-left: rem(72px);
    }

    @media (max-width: $tablet) {
      width: 100%;
      margin-left: rem(48px);
      padding-top: 0;
    }

    @media (max-width: $mobile) {
      margin-left: rem(16px);
      padding-right: rem(16px);
    }
  }

  &__blocks {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 30px;

    @media (max-width: $desktop) {
      margin-top: 5rem;
    }

    @media (max-width: $tablet) {
      flex-direction: column;
    }

    @media (min-width: 1150px) and (max-width: 1599px) and (max-height: 890px) {
      margin-top: rem(120px);
    }

    @media (min-width: 1600px) and (max-height: 890px) {
      margin-top: rem(140px);
    }
  }

  &__address-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 50%;

    @media (max-width: $tablet) {
      width: 100%;
      margin-bottom: rem(132px);
    }

    @media (max-width: $mobile) {
      margin-bottom: rem(65px);
    }
  }

  &__block_maps {
    width: 100%;
    height: 100%;
    height: 600px;

    @media (max-width: $tablet) {
      width: calc(100% - 48px);
      margin-bottom: rem(132px);
      margin-bottom: 0;
    }

    @media (max-width: $mobile) {
      width: calc(100% - 16px);
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__block_contacts {
    width: 100%;
    @media (max-width: $tablet) {
      width: 100%;
      margin-bottom: 0;
    }

    @media (max-width: $mobile) {
      margin-bottom: 0;
    }
  }

  &__block-telegram {
    margin-bottom: rem(192px);

    @media (max-width: $desktop) {
      margin-bottom: rem(92px);
    }
    @media (max-width: $tablet) {
      margin-bottom: 0;
    }
  }

  &__adress {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(24px);

    @media (max-width: $mobile) {
      margin-bottom: rem(19px);
    }

    span {
      margin-top: rem(2px);
      margin-bottom: rem(8px);

      font-size: rem(18px);
      line-height: rem(24px);
      letter-spacing: 0.01em;
      font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

      @media (max-width: $mobile) {
        margin-top: 0;
        margin-bottom: rem(7px);
        font-size: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__mail {
    margin-bottom: rem(16px);

    @media (max-width: $tablet) {
      margin-bottom: rem(18px);
    }

    @media (max-width: $mobile) {
      margin-bottom: rem(7px);
    }
  }

  &__mail a {
    @include interface-bold();
    font-size: rem(44px);
    line-height: rem(48px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
    color: $blue;
    transition: 0.5s;

    @media (max-width: $mobile) {
      font-size: rem(20px);
      line-height: rem(32px);
    }
  }

  &__mail a:hover {
    color: $light-blue;
  }

  &__phone {
    margin-bottom: rem(96px);

    @media (max-width: $desktop) {
      margin-bottom: rem(132px);
    }

    @media (max-width: $tablet) {
      margin-bottom: rem(65px);
    }

    @media (max-width: $mobile) {
      margin-bottom: rem(30px);
    }
  }

  &__phone a {
    @include interface-bold();
    font-size: rem(44px);
    line-height: rem(48px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
    transition: 0.5s;

    @media (max-width: $mobile) {
      font-size: rem(20px);
      line-height: rem(32px);
    }
  }

  &__phone a:hover {
    color: $light-blue;
  }

  &__form-loader {
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(480px);

    @media (max-width: $mobile) {
      width: 100%;
    }
  }
}

.request-form {
  width: rem(480px);

  @media (max-width: $desktop) {
    width: rem(528px);
  }

  @media (max-width: $tablet) {
    width: rem(672px);
  }

  @media (max-width: $mobile) {
    width: 100%;
  }

  & span.request-error {
    display: none !important;
  }

  &__text {
    font-size: rem(12px);
    line-height: rem(16px);
    letter-spacing: 0.01em;
    color: #ffffff;

    @media (max-width: $tablet) {
      width: rem(430px);
    }

    @media (max-width: $mobile) {
      width: 100%;
    }

    a {
      text-decoration: underline;
    }
  }

  &__file {
    display: none;
  }

  &__submit {
    margin-top: rem(50px);
    margin-bottom: rem(40px);
    width: rem(240px);
    height: rem(56px);
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #1f72fa;
    border-radius: rem(4px);
    border: none;
    box-shadow: none;

    @include interface-bold();
    font-size: rem(16px);
    line-height: rem(20px);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #ffffff;
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
    outline: none;

    transition: 0.5s;
    cursor: pointer;

    @media (max-width: $tablet) {
      margin-top: rem(46px);
      margin-bottom: rem(41px);
    }

    @media (max-width: $mobile) {
      width: 100%;
      margin-top: 0;
      margin-bottom: rem(36px);
    }
  }

  &__wrap {
    position: relative;
    margin-bottom: rem(30px);

    &.completed,
    &.focus,
    &.hover {
      .request-form__placeholder {
        top: rem(-18px);
        font-size: rem(12px);
        line-height: rem(16px);
      }
    }

    @media (max-width: $desktop) {
      margin-bottom: rem(31px);
    }

    @media (max-width: $mobile) {
      margin-bottom: rem(30px);
    }
  }

  &__wrap_alert::before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    height: rem(16px);
    width: rem(16px);

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23F74A14'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 6C8.82843 6 9.5 5.32843 9.5 4.5C9.5 3.67157 8.82843 3 8 3C7.17157 3 6.5 3.67157 6.5 4.5C6.5 5.32843 7.17157 6 8 6ZM9 9C9 8.44772 8.55228 8 8 8C7.44772 8 7 8.44772 7 9L7 12C7 12.5523 7.44772 13 8 13C8.55228 13 9 12.5523 9 12L9 9Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  &__wrap_file {
    position: relative;
  }

  &__wrap_file:before {
    position: absolute;
    right: 0;
    top: rem(4px);
    width: rem(16px);
    height: rem(16px);

    display: block;
    content: '';

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7369 7.24583C14.3379 6.84277 13.7394 6.84277 13.4402 7.24583L7.65519 13.0902C6.35854 14.4001 4.26396 14.4001 2.96732 13.0902C1.67068 11.7802 1.67068 9.56342 2.96732 8.25348L8.75235 2.40912C9.45054 1.70377 10.6474 1.60301 11.4454 2.40912C12.1436 3.21524 12.1436 4.42442 11.4454 5.12977L5.66035 10.9741C5.46087 11.1757 5.16164 11.1757 4.96216 10.9741C4.76267 10.7726 4.76267 10.4703 4.96216 10.2688L8.05415 7.14507C8.45312 6.74201 8.45312 6.13742 8.05415 5.73436C7.65519 5.3313 7.05673 5.3313 6.75751 5.73436L3.56577 8.95883C2.66809 9.86572 2.66809 11.478 3.56577 12.3848C4.46345 13.2917 5.95957 13.2917 6.95699 12.3848L12.742 6.54048C14.2381 5.02901 14.2381 2.50989 12.742 0.998418C11.2459 -0.513053 8.85209 -0.109994 7.35596 0.897653L1.57093 6.84277C-0.523644 8.95883 -0.523644 12.3848 1.57093 14.4001C3.56577 16.5162 6.85725 16.5162 8.95183 14.5009C8.95183 14.5009 8.95183 14.5009 9.05157 14.4001L14.7369 8.55577C15.1358 8.15271 15.0361 7.54813 14.7369 7.24583Z' fill='%231F72FA'/%3E%3C/svg%3E%0A");
  }

  &__wrap_file:hover:before {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7369 7.24583C14.3379 6.84277 13.7394 6.84277 13.4402 7.24583L7.65519 13.0902C6.35854 14.4001 4.26396 14.4001 2.96732 13.0902C1.67068 11.7802 1.67068 9.56342 2.96732 8.25348L8.75235 2.40912C9.45054 1.70377 10.6474 1.60301 11.4454 2.40912C12.1436 3.21524 12.1436 4.42442 11.4454 5.12977L5.66035 10.9741C5.46087 11.1757 5.16164 11.1757 4.96216 10.9741C4.76267 10.7726 4.76267 10.4703 4.96216 10.2688L8.05415 7.14507C8.45312 6.74201 8.45312 6.13742 8.05415 5.73436C7.65519 5.3313 7.05673 5.3313 6.75751 5.73436L3.56577 8.95883C2.66809 9.86572 2.66809 11.478 3.56577 12.3848C4.46345 13.2917 5.95957 13.2917 6.95699 12.3848L12.742 6.54048C14.2381 5.02901 14.2381 2.50989 12.742 0.998418C11.2459 -0.513053 8.85209 -0.109994 7.35596 0.897653L1.57093 6.84277C-0.523644 8.95883 -0.523644 12.3848 1.57093 14.4001C3.56577 16.5162 6.85725 16.5162 8.95183 14.5009C8.95183 14.5009 8.95183 14.5009 9.05157 14.4001L14.7369 8.55577C15.1358 8.15271 15.0361 7.54813 14.7369 7.24583Z' fill='%2316CFFE'/%3E%3C/svg%3E%0A");
  }

  &__submit:hover {
    background-color: #16cffe;
    color: #fff;
  }

  &__submit:active {
    background-color: #105ad2;
    color: rgba(#fff, 0.5);
  }

  &__submit:disabled,
  &__submit:hover:disabled {
    background-color: rgba(#ffffff, 0.1);

    color: rgba(#fff, 0.1);
  }

  &__input {
    position: relative;
    z-index: 1;
    padding-bottom: rem(8px);
    width: 100%;

    font-size: rem(18px);
    line-height: rem(24px);
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    background: none;
    border: none;
    border-bottom: 1px solid #4d5460;
    outline: none;
    transition: border-bottom-color 0.25s ease-in;

    color: #fff;

    &--textarea {
      height: rem(32px);
      max-height: rem(124);
      resize: none;
      overflow: hidden;
    }

    &:focus {
      border-bottom: 1px solid $blue;
    }

    &:hover {
      &::placeholder {
        opacity: 0;
      }
    }

    &::placeholder {
      color: #4d5460;
    }
  }

  &__input.request-error,
  &__input:focus.request-error {
    border-bottom: 1px solid #f74a14;

    color: #f74a14;
  }

  &__input-fake {
    position: relative;
    z-index: 1;
    padding-bottom: rem(8px);
    width: 100%;
    height: rem(32px);
    display: block;

    font-size: rem(18px);
    line-height: rem(24px);
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    border-bottom: 1px solid #4d5460;
    cursor: pointer;

    color: #4d5460;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.active {
      color: #fff;
    }
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    font-size: rem(18px);
    line-height: rem(24px);
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
    color: #606773;
    transition: all 0.25s ease-in;
  }

  &__text-input {
    color: $gray;
    font-size: 14px;
    margin-top: 5px;

    &_error {
      color: $red;
    }
  }
}

.content-list,
.slides-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content-list__item {
  display: flex;
  align-items: center;
  height: 100vh;

  @media (max-width: $tablet) {
    height: auto;
    margin-bottom: rem(64px);

    &:nth-child(even) {
      .proj-slider__link {
        margin-left: auto;
      }
    }
  }

  @media (max-width: $mobile) {
    margin-bottom: rem(61px);
  }
}

.content {
  overflow: hidden;
}

.slides-list {
  position: relative;
  display: flex;
  width: 200%;
  overflow: hidden;
  transition: background-color 1s;

  &__item {
    width: 200%;
    height: 100vh;
    display: flex;
    opacity: 0;
    align-items: center;
    transition: opacity 0.25s ease-out;
  }
}

.slides-list-wrap {
  width: 0;
  overflow: hidden;
  transition: width 0.5s ease-out;

  &.show {
    width: 100%;
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 50%;

    .slides-list__item {
      opacity: 1;
    }
  }

  &.bottom {
    position: absolute;
    top: auto;
    bottom: 0;
  }
}

.form-success {
  display: none;

  &__title {
    margin-bottom: rem(32px);

    @include interface-bold();
    font-size: rem(44px);
    line-height: rem(48px);
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
  }

  &__desc {
    width: rem(480px);
    margin-bottom: rem(64px);

    font-size: rem(18px);
    line-height: rem(24px);
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  &__button {
    display: flex;
    width: rem(240px);
    height: rem(56px);

    align-items: center;
    justify-content: center;

    background: #1f72fa;
    border-radius: 4px;

    transition: 0.5s;

    &:hover {
      background-color: $dark-blue;
    }
  }
}

.form-error {
  display: none;

  &__desc {
    width: rem(480px);
    margin-bottom: rem(64px);

    font-size: rem(18px);
    line-height: rem(24px);
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  &__button {
    display: flex;
    width: rem(240px);
    height: rem(56px);

    align-items: center;
    justify-content: center;

    background: #1f72fa;
    border-radius: 4px;

    transition: 0.5s;

    &:hover {
      background-color: $dark-blue;
    }
  }
}

.form-loader {
  display: none;

  svg {
    width: 54px;
    height: 100px;
    margin: 20px;
    display: inline-block;
  }
}

.group {
  height: 100% !important;
}

.popup {
  &__wrapper {
    width: 90%;
    margin: 0 auto;
    padding: 10% 0;

    @media (max-width: $tablet) {
      height: 100vh;
      padding: 15% 0;
    }

    @media (max-width: $mobile) {
      padding: 20% 0;
    }
  }

  &__content {
    @media (max-width: $tablet) {
      height: 100%;
      overflow-y: auto;
    }
  }

  &__title {
    font-size: rem(20px);
  }

  &__text {
    margin-top: rem(20px);
  }
}

@keyframes animation-numb {
  0% {
    width: rem(2px);
  }

  100% {
    width: rem(40px);
  }
}

// news-main

.news-main {
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    height: rem(895px);
  }

  @media (max-width: $mobile) {
    height: auto;
    padding-top: rem(105px);
  }

  &__wrapper {
    width: rem(1200px);
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $desktop) {
      width: rem(1056px);
    }

    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &__title {
    font-size: 20px;
  }

  &__slider {
    margin-top: 6rem;
  }
}

.news-main-slider {
  padding-bottom: rem(80px);

  @media (max-width: $desktop) {
    padding-bottom: rem(60px);
  }

  @media (max-width: $tablet) {
    margin-top: 0;
  }

  &__item {
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    width: calc(100% - 40px) !important;
  }

  &__img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    object-fit: cover;
    width: 100%;
    height: 230px;
  }

  &__top-slide {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 10px;
  }

  &__date {
    display: block;
    margin-right: 10px;
  }

  &__name-new {
    display: block;
  }

  &__date,
  &__name-new {
    font-size: 14px;
  }

  &__content {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;

    p {
      margin-top: 0;
    }
  }

  .slick-slide {
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.slick-active {
      opacity: 1;
    }
  }

  .slick-list {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);

    @media (max-width: $tablet) {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  .slick-arrow {
    top: auto;
    bottom: rem(-12px);
    width: rem(47px);
    height: rem(23px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;

    transition: background 0.5s ease-out, filter 0.5s ease-out;
    outline: none;

    &.slick-next {
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='48' height='24' viewBox='0 0 48 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M43.5859 13L0 13V11L43.5857 11L34.2929 1.70718L35.7071 0.292969L46.7071 11.293L47.4142 12.0001L46.7071 12.7072L35.7071 23.7072L34.2929 22.293L43.5859 13Z' fill='%231F72FA'/%3E%3C/svg%3E%0A");

      &:hover {
        filter: brightness(1.5);
      }

      @media (max-width: $tablet) {
        right: rem(50);
      }

      @media (max-width: $mobile) {
        right: rem(20);
      }
    }

    &.slick-prev {
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='48' height='24' viewBox='0 0 48 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.41414 13L48 13V11L4.41429 11L13.7071 1.70718L12.2929 0.292969L1.29289 11.293L0.585785 12.0001L1.29289 12.7072L12.2929 23.7072L13.7071 22.293L4.41414 13Z' fill='%231F72FA'/%3E%3C/svg%3E%0A");

      &:hover {
        filter: brightness(1.5);
      }

      @media (max-width: $tablet) {
        left: rem(50);
      }

      @media (max-width: $mobile) {
        left: rem(20);
      }
    }

    &:before {
      display: none;
    }
  }

  .slider__numb.animation:after {
    animation-duration: 15s;
  }
}
