.telegram-info {
    display: flex;
    align-items: center;
    gap: 15px;

    &__text {
        display: block;
        width: 176px;

        @media (max-width: $mobile) {
            width: auto;
            max-width: 270px;
        }
    }

    &__icon {
        width: 32px;
        height: 32px;
        fill: $white;
        transition: .5s;
    }

    &:hover {
        .telegram-info__icon {
            fill: $light-blue;
        }
    }
}