.project-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__item {
    position: relative;
    width: rem(600px);
    margin-bottom: rem(112px);

    @media (max-width: $desktop) {
      width: rem(440px);
      margin-bottom: rem(90px);
    }

    @media (max-width: $tablet) {
      width: rem(448px);
      margin-bottom: rem(65px);
    }

    @media (max-width: $mobile) {
      position: relative;

    }

    &:nth-child(even) {
      top: rem(256px);

      @media (max-width: $desktop) {
        top: rem(192px);
      }

      @media (max-width: $tablet) {
        margin-left: auto;
        top: auto;
      }
    }
  }

  &__img-block {
    width: rem(600px);
    height: rem(576px);

    @media (max-width: $desktop) {
      width: rem(440px);
      height: rem(422px);
    }

    @media (max-width: $tablet) {
      width: rem(448px);
      height: rem(430px);
    }

    @media (max-width: $mobile) {
      width: 100%;
      height: rem(307px);
    }

    &_01 {
      background-color: #2F3EC2;
    }
    &_02 {
      background-color: #CA2525;
    }
    &_03 {
      background-color: #7E2420;
    }
    &_04 {
      background-color: #B4965A;
    }
    &_05 {
      background-color: #1F72FA;
    }
    &_06 {
      background-color: #CA2525;
    }
    &_07 {
      background-color: #E01E5A;
    }
    &_08 {
      background-color: #F64A14;
    }
    &_09 {
      background-color: #1AD6B7;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__block-link {
    display: block;

    &:hover {
      .project-list__num {
        color: $light-blue;
      }

      .project-list__header {
        color: $light-blue;
      }

      .project-list__text-block {
        transform: translateY(rem(-24px));
      }
    }
  }

  &__content {
    padding: rem(32px) 0 0;

    @media (max-width: $mobile) {
      padding: rem(16px) rem(16px) 0;
    }
  }

  &__num {
    display: none;

    @media (max-width: $mobile) {
      display: block;
      font-feature-settings: 'tnum' on, 'lnum' on, 'zero' on;
      font-size: rem(18px);
      line-height: rem(24px);
      cursor: default;
    }
  }

  &__header {
    @include interface-bold;

    margin: 0 0 rem(16px);
    font-size: rem(50px);
    line-height: rem(56px);
    cursor: pointer;
    transition: color 0.25s ease-in;

    @media (max-width: $mobile) {
      font-size: rem(34px);
      line-height: rem(40px);
    }
  }

  &__text {
    margin: 0 0 rem(33px);
    font-size: rem(18px);
    line-height: rem(24px);
    cursor: pointer;
    color: $gray;


    @media (max-width: $mobile) {
      color: #fff;
    }
  }

  &__link {
    display: inline-block;
    height: rem(24px);
    overflow: hidden;
  }

  &__text-block {
    display: flex;
    align-items: center;
    transition: all 0.25s ease-in-out;

    .project-list__arrow-more {
      fill: $light-blue;
    }

    .project-list__arrow-more {
      color: $light-blue;
    }
  }

  &__link-text {
    @include interface-bold;

    display: inline-block;
    font-size: rem(16px);
    line-height: rem(20px);
    color: $light-blue;
    padding-right: rem(16px);
    text-transform: uppercase;
    transition: color 0.25s ease-in;
  }
}
